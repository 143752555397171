<template>
  <div>
    <Modal :options="{width: '40vw', type: 'danger'}" @close="$emit('close')">
      <form @submit.prevent="marcar_inviable">
        <div class="title">Solicitud {{ solicitud.solicitud }} inviable</div>
        <div class="body">
          <div class="row form-group">
            <label for="razon_inviable" class="col-form-label col-sm-2">Razón de inviavilidad</label>
            <div class="col-sm-10"><textarea v-model="razon" name="razon_inviable" id="razon_inviable" cols="30" rows="10" class="form-control"></textarea></div>
          </div>
        </div>
        <div class="footer">
          <div class="row">
            <div class="col-sm-12 text-right">
              <button class="btn btn-primary mr-2">Marcar como inviable</button>
              <button class="btn btn-danger" type="button" @click="$emit('close')">Cancelar</button>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  </div>
</template>

<script type="text/javascript">
  import Modal from '@/components/Modal'

  import apiSolicitud from '@/apps/formalizacion/api/v2/solicitudes'

  export default {
    components: {
      Modal
    }
    ,props: {
      solicitud: {
        type: Object
        ,dafault() {
          return {
            id: null
            ,solicitud: null
          }
        }
      }
    }
    ,data() {
      return {
        razon: null
      }
    }
    ,methods: {
      async marcar_inviable() {
        try {
          if (!this.razon)
            return this.$helper.showMessage('Error','Debes definir la razón por la cual se marca como inviable la solicitud','error','alert');

          let res = (await apiSolicitud.editar_solicitud(this.solicitud.id, {
            razon_inviable: this.razon
          }));

          this.$log.info('res', res)
          this.$emit('update');
        }catch(e) {
          this.$log.info('error',e)
          this.$helper.showAxiosError(e,'Error');
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  textarea {
    resize: none;
  }
</style>