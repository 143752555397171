<template>
  <div>
    
    <div class="row mb-3 text-right">
      <div class="col-sm-12">
        <button v-if="!solicitud.documentacion_viable_at" class="btn btn-success mr-2" @click="marcar_vable">Marcar como viable solicitud</button>
        <button v-if="!solicitud.documentacion_viable_at" class="btn btn-danger mr-2" @click="solicitud_inviable=true">Marcar como inviable la solicitud</button>
        <button class="btn btn-info mr-2" @click="obtener_documentos(true)">Recargar documentos</button>
        <button class="btn btn-warning mr-2" @click="invalidar_documento">Invalidar documentos</button>
        <button v-if="$auth.can('valija','solicitudes_documentos_cargar',false)" class="btn btn-secondary" @click="modal_cargar_documento=true">Cargar documento(s)</button>
      </div>
    </div>

    <div class="row">
      <div v-for="documento in valija.documentos" class="col-sm-5 col-md-4 col-lg-3">
        <div class="card mt-3">
          <div class="card-body">
            <h5 class="card-title text-truncate" :title="buscar_nombre(documento.tipo_documento)"><BpBCheckbox name="documentos[]" :value="$helper.toJson(documento)" @checked="agregar_documentos" />{{ buscar_nombre(documento.tipo_documento) }}</h5>
            <h6 class="card-subtitle mb-2 text-muted text-truncate" :title="documento.nombre_original">{{ documento.nombre_original }}</h6>
            <div class="card-text">
              <ul>
                <li>Tipo: {{ documento.extension }}</li>
                <li>Peso: {{ $helper.bytesConverted(documento.peso) }}</li>
                <li>Versión: {{ documento.version }}</li>
                <li>Estatus: {{ documento.estatus == 1 ? 'Activo' : 'Error' }}</li>
              </ul>
            </div>
            <div class="text-right">
              <a href="#" class="card-link" @click.prevent="descargar_documento(documento)">Descargar archivo</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Modal v-if="modal_invalidar_documento" :options="{width: '40vw', type:'danger'}">
      <div class="title">Invalidar documento</div>
      <div class="body">
        <div class="mt-3">
          <p>Para poder invalidar los documentos debes indicar una razón</p>
          <div v-for="(doc,index) in documentos_invalidar" :key="index" class="row form-group">
            <label :for="'doc_'+doc.tipo_documento" class="col-form-label col-sm-3">{{ buscar_nombre(doc.tipo_documento) }}</label>
            <div class="col-sm-9">
              <input type="text" :name="'doc_'+doc.tipo_documento" :id="'doc_'+doc.tipo_documento" :data-index="index" class="form-control" placeholder="Razón de invalidación..." @change="agregar_razon">
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="row">
          <div class="col-sm-12 text-right">
            <button class="btn btn-primary mr-2" @click="invalidar_documentos">Invalidar</button>
            <button class="btn btn-danger" @click="modal_invalidar_documento=null">Cancelar</button>
          </div>
        </div>
      </div>
    </Modal>

    <CargarDocumento v-if="modal_cargar_documento" :solicitud="solicitud" @update="cargar_documento_finalizar"  @close="modal_cargar_documento=false"  />

    <SolicitudInviable v-if="solicitud_inviable" :solicitud="solicitud" @close="solicitud_inviable=false" />
  </div>
</template>

<script type="text/javascript">
  import Modal from '@/components/Modal'
  import BpBCheckbox from '@/components/Form/Checkbox'
  import CargarDocumento from '@/apps/formalizacion/Componentes/CargarDocumento'
  import SolicitudInviable from '@/apps/formalizacion/Componentes/SolicitudInviable'

  import apiValija from '@/apps/formalizacion/api/v2/valija'
  import apiSolicitudes from '@/apps/formalizacion/api/v2/solicitudes'

  export default {
    components: {
      Modal, BpBCheckbox, CargarDocumento, SolicitudInviable
    }
    ,props: {
      solicitud: {
        type: Object
        ,default() {
          return {
            solicitud: null
          }
        }
      }
    }
    ,data() {
      return {
        valija: {
          documentos: []
        }
        ,catalogo: null
        ,modal_invalidar_documento: null
        ,modal_cargar_documento: false
        ,documentos_invalidar: []
        ,solicitud_inviable: false
      }
    }
    ,mounted() {
      if (this.solicitud.solicitud)
        this.obtener_documentos();

      this.obtener_catalogos();
    }
    ,methods: {
      async obtener_documentos(clear) {
        try {
          if (typeof clear != 'undefined')
            this.$helper.cache_clear('valija.'+this.solicitud.solicitud);

          this.valija = await this.$helper.cache_store('valija.'+this.solicitud.solicitud, async () => {
            return (await apiValija.listar_documentos(this.solicitud.solicitud)).data;
          });
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,async obtener_catalogos() {
        try {
          this.catalogo = await this.$helper.cache_store('valija.catalogos.tipos_archivos', async () => {
            return (await apiValija.obtener_catalogo('tipos_archivos')).data;
          });

          if (this.solicitud.solicitud)
            this.obtener_documentos();
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,buscar_nombre(tipo) {
        let tipo_archivo = null;

        for(let i=0; i<this.catalogo.opciones.length; i++) {
          if (tipo == this.catalogo.opciones[i].valor)
            tipo_archivo = this.catalogo.opciones[i];
        }

        return tipo_archivo ? tipo_archivo.nombre : 'N/A';
      }
      ,async descargar_documento(documento) {
        try {
          let res = (await apiValija.descargar_documento(this.solicitud.solicitud, documento.id)).data;

          let url = window.URL.createObjectURL(new Blob([res]));
          let link = document.createElement('a');
          link.href = url;
          link.setAttribute('download',documento.nombre_original);
          document.body.appendChild(link);
          link.click();
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,async invalidar_documento() {
        if (this.documentos_invalidar.length == 0)
          return this.$helper.showMessage('Error','Debes seleccionar al menos un documento','error','alert');

        this.modal_invalidar_documento = true;
      }
      ,async invalidar_documentos() {
        try {
          if (this.documentos_invalidar.length == 0)
            return this.$helper.showMessage('Error','Debes seleccionar al menos un documento','error','alert');

          this.documentos_invalidar.forEach(async documento => {
            let res = (await apiValija.invalidar_documento(this.solicitud.solicitud, documento.id)).data;
            this.$log.info('resvalija', res);

            let doc = null;
          })

          let a_invalidar = [];
          this.documentos_invalidar.forEach(documento =>  {
            a_invalidar.push({
              tipo: this.buscar_nombre(documento.tipo_documento)
              ,razon: documento.razon
            })
          });

          let res = (await apiSolicitudes.notificacion_documentos_invalidos(this.solicitud.solicitud, {documentos: a_invalidar})).data
          this.$log.info('resformalizacion', res);

          let checkboxs = document.querySelectorAll('input[name="documentos[]"]:checked');
          this.$log.info('checkbox', checkboxs);

          checkboxs.forEach(checkbox => {
            checkbox.checked = false;
          })

          this.obtener_documentos(true);
          this.modal_invalidar_documento = null;
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,cargar_documento_finalizar() {
        this.obtener_documentos();
        this.modal_cargar_documento = false;
      }
      ,agregar_documentos(documentos) {
        this.documentos_invalidar = [];
        documentos.forEach(doc => {
          this.documentos_invalidar.push(this.$helper.fromJson(doc));
        })


        this.$log.info('documentos_invalidar', this.documentos_invalidar);
      }
      ,agregar_razon(e) {
        let index = e.target.dataset.index;
        this.$log.info('index',index)
        this.$log.info('value',e.target.value)

        for(let i=0; i<this.documentos_invalidar.length; i++) {
          if (i == index) {
            this.documentos_invalidar[i].razon = e.target.value;
            i=this.documentos_invalidar.length;
          }
        }
      }
      ,async marcar_vable() {
        try {
          let res = (await apiSolicitudes.editar_solicitud(this.solicitud.id, {documentacion_viable: true})).data
          this.$log.info('res', res);

          this.$emit('update');
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
    }
    ,watch: {
      solicitud: {
        handler() {
          if (this.valija.documentos.length <= 0)
            this.obtener_documentos();
        }
        ,deep: true
      }
    }
  }
</script>